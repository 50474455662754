/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Apartmán k pronájmu v Bulharsku"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="pb--20 pt--20" name={"[[UNIsecname1]]-0"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--1 flex--center" anim={"2"} animS={"8"} columns={"1"}>
            
            <ColumnWrapper className="pb--40 pt--40" style={{"maxWidth":780}}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: var(--color-dominant);\">Galerie</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--20" name={"jomvvsgd3pj"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--1 flex--center" anim={"2"} animS={"8"} columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l8"} name={"14r471nym8f"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/c1505941f985475f804656406fb1d2e9_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/c1505941f985475f804656406fb1d2e9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/c1505941f985475f804656406fb1d2e9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/c1505941f985475f804656406fb1d2e9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/c1505941f985475f804656406fb1d2e9_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/28418604424a403e967299410fd573b7_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/28418604424a403e967299410fd573b7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/28418604424a403e967299410fd573b7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/28418604424a403e967299410fd573b7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/28418604424a403e967299410fd573b7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/28418604424a403e967299410fd573b7_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/f398935cdb6347d28baacc81d3852fc1_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/f398935cdb6347d28baacc81d3852fc1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/f398935cdb6347d28baacc81d3852fc1_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/d233776a873e45ae834c202b910b198e_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/d233776a873e45ae834c202b910b198e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/d233776a873e45ae834c202b910b198e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/d233776a873e45ae834c202b910b198e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/d233776a873e45ae834c202b910b198e_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/cf7afcd4207747c1a730d56234f050b6_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/cf7afcd4207747c1a730d56234f050b6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/cf7afcd4207747c1a730d56234f050b6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/cf7afcd4207747c1a730d56234f050b6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/cf7afcd4207747c1a730d56234f050b6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/cf7afcd4207747c1a730d56234f050b6_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/7d9e774176374671b5d4aea4a4d00857_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/7d9e774176374671b5d4aea4a4d00857_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/7d9e774176374671b5d4aea4a4d00857_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/7d9e774176374671b5d4aea4a4d00857_s=860x_.jpg 860w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/0c8b517191154a6ab4f7bd4c6c43c4d8_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/0c8b517191154a6ab4f7bd4c6c43c4d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/0c8b517191154a6ab4f7bd4c6c43c4d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/0c8b517191154a6ab4f7bd4c6c43c4d8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/0c8b517191154a6ab4f7bd4c6c43c4d8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/0c8b517191154a6ab4f7bd4c6c43c4d8_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/2209b31d279440079b1d3eb0df2f5001_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/2209b31d279440079b1d3eb0df2f5001_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/2209b31d279440079b1d3eb0df2f5001_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/2209b31d279440079b1d3eb0df2f5001_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/2209b31d279440079b1d3eb0df2f5001_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/2209b31d279440079b1d3eb0df2f5001_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/2adf3c8d8c20498781cab75ed96f3903_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/2adf3c8d8c20498781cab75ed96f3903_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/2adf3c8d8c20498781cab75ed96f3903_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/2adf3c8d8c20498781cab75ed96f3903_s=860x_.jpg 860w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/09609671aa344ef8a854402076d50bb4_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/09609671aa344ef8a854402076d50bb4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/09609671aa344ef8a854402076d50bb4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/09609671aa344ef8a854402076d50bb4_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/c2246d8d23d041a18a40ce33e6c42ff9_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/c2246d8d23d041a18a40ce33e6c42ff9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/c2246d8d23d041a18a40ce33e6c42ff9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/c2246d8d23d041a18a40ce33e6c42ff9_s=860x_.jpg 860w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/3d73162e5a404e93a6237be259318d20_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/3d73162e5a404e93a6237be259318d20_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/3d73162e5a404e93a6237be259318d20_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/3d73162e5a404e93a6237be259318d20_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/3d73162e5a404e93a6237be259318d20_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/db18df439e35468595c0c20c1da909d0_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/db18df439e35468595c0c20c1da909d0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/db18df439e35468595c0c20c1da909d0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/db18df439e35468595c0c20c1da909d0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/db18df439e35468595c0c20c1da909d0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/db18df439e35468595c0c20c1da909d0_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/ba4a6732fc3a40848aa5404ea4fcb3a1_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/ba4a6732fc3a40848aa5404ea4fcb3a1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/ba4a6732fc3a40848aa5404ea4fcb3a1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/ba4a6732fc3a40848aa5404ea4fcb3a1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/ba4a6732fc3a40848aa5404ea4fcb3a1_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/df78f36bb79f4942a0d53482080b5fa8_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/df78f36bb79f4942a0d53482080b5fa8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/df78f36bb79f4942a0d53482080b5fa8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/df78f36bb79f4942a0d53482080b5fa8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/df78f36bb79f4942a0d53482080b5fa8_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/d6a9658bc9c841afadcefcca57c5bd2d_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/d6a9658bc9c841afadcefcca57c5bd2d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/d6a9658bc9c841afadcefcca57c5bd2d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/d6a9658bc9c841afadcefcca57c5bd2d_s=860x_.jpg 860w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/1dc76366b1414279ac28c158cc820185_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/1dc76366b1414279ac28c158cc820185_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/1dc76366b1414279ac28c158cc820185_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/1dc76366b1414279ac28c158cc820185_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/1dc76366b1414279ac28c158cc820185_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/ae9b7cf2c9074e79aabd690713f43deb_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/ae9b7cf2c9074e79aabd690713f43deb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/ae9b7cf2c9074e79aabd690713f43deb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/ae9b7cf2c9074e79aabd690713f43deb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/ae9b7cf2c9074e79aabd690713f43deb_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/39506/ae9b7cf2c9074e79aabd690713f43deb_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39506/8ff8626fd5354145b530766e39ea6f2e_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/8ff8626fd5354145b530766e39ea6f2e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/8ff8626fd5354145b530766e39ea6f2e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/8ff8626fd5354145b530766e39ea6f2e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/8ff8626fd5354145b530766e39ea6f2e_s=1400x_.jpg 1400w"} style={{"maxWidth":1000}}>
              </Image>

              <Image style={{"maxWidth":440}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/1eae9c22d9904a29aa6d8d28290c4642_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/1eae9c22d9904a29aa6d8d28290c4642_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/1eae9c22d9904a29aa6d8d28290c4642_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/1eae9c22d9904a29aa6d8d28290c4642_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39506/1eae9c22d9904a29aa6d8d28290c4642_s=860x_.jpg"}>
              </Image>

              <Image style={{"maxWidth":440}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/2adf3c8d8c20498781cab75ed96f3903_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/2adf3c8d8c20498781cab75ed96f3903_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/2adf3c8d8c20498781cab75ed96f3903_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/39506/2adf3c8d8c20498781cab75ed96f3903_s=660x_.jpg"}>
              </Image>

              <Image style={{"maxWidth":440}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/ff04cdac90fc4f17b65d6190e18f8021_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/ff04cdac90fc4f17b65d6190e18f8021_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/ff04cdac90fc4f17b65d6190e18f8021_s=860x_.jpg 860w, https://cdn.swbpg.com/t/39506/ff04cdac90fc4f17b65d6190e18f8021_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39506/ff04cdac90fc4f17b65d6190e18f8021_s=860x_.jpg"}>
              </Image>

              <Image style={{"maxWidth":440}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39506/b8cdc1194c024571b073c6eb2cceec99_s=350x_.jpg 350w, https://cdn.swbpg.com/t/39506/b8cdc1194c024571b073c6eb2cceec99_s=660x_.jpg 660w, https://cdn.swbpg.com/t/39506/b8cdc1194c024571b073c6eb2cceec99_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/39506/b8cdc1194c024571b073c6eb2cceec99_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}